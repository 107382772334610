<template>
  <div
    :class="
      triggerClass +
      ' absolute !h-[16px] !w-[16px] flex justify-center items-center cursor-pointer'
    "
    @mouseenter="() => (!disabled && !isTouch ? openPopup() : null)"
    @mouseleave="() => !isTouch && closePopup()"
    @touchstart="() => (open || disabled ? closePopup() : openPopup())"
    @click="() => togglePopup()"
  >
    <slot name="trigger" />
  </div>

  <div
    ref="container"
    class="absolute hidden w-full z-[1001]"
    :class="[
      {
        'left-full top-0': position === 'right',
        'w-full top-full mt-[-12px] justify-end right-0 left-0':
          position === 'bottom',
        '!flex ': open,
      },
      contentClass,
    ]"
    :style="position === 'right' ? { width: containerWidth + 'px' } : {}"
  >
    <InfoFrame
      :has-cancel="true"
      class="min-w-[315px] max-w-[calc(100vw-var(--thm-spacing-lg))] md:max-w-[450px]"
      :class="{
        'ml-[18px] right': position === 'right',
        'mt-[18px] bottom': position === 'bottom',
      }"
      @cancel="closePopup"
      ><slot name="content"
    /></InfoFrame>
  </div>
</template>
<script setup lang="ts">
import { useInfoPopup } from '~/components/components/infoPopup/useInfoPopup';
import InfoFrame from '~/components/formFields/components/infoFrame.vue';

const props = defineProps({
  triggerClass: {
    type: String,
    default: '',
  },
  contentClass: {
    type: String,
    default: '',
  },
  minRightWidth: {
    type: Number,
    default: 348,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  infoOnClick: {
    type: Boolean,
    default: false,
  },
});

const {
  container,
  open,
  position,
  containerWidth,
  isTouch,
  openPopup,
  closePopup,
  togglePopup,
} = useInfoPopup(props.minRightWidth, props.infoOnClick);
</script>
<style lang="postcss" scoped>
:deep(.arrow) {
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }
  &.right::before {
    top: 20px;
    left: 10px;
    transform: translateY(-50%);
    border-width: 8px 8px 8px 0;
    border-color: transparent var(--thm-info-popup-bg) transparent transparent;
  }
  &.bottom::before {
    top: 10px;
    right: 10px;
    transform: translateX(-50%);
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent var(--thm-info-popup-bg) transparent;
  }
}
</style>
