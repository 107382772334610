import { SSR_safe_mq_isTouch } from '~/injectionSymbols';

export function useInfoPopup(minRightWidth: number, infoOnClick: boolean) {
  const container = ref<HTMLElement | null>(null);
  const open = ref(false);
  const position = ref<'right' | 'bottom'>('right');
  const containerWidth = ref(minRightWidth);
  const isInDialog = useIsInDialog(container);
  const isTouch = inject(SSR_safe_mq_isTouch);

  function openPopup(force = false) {
    if (infoOnClick && !force) return;
    calculatePostion();
    open.value = true;
  }

  function closePopup(force = false) {
    if (infoOnClick && !force) return;
    calculatePostion();
    open.value = false;
  }

  function togglePopup() {
    if (!infoOnClick) return;
    if (!open.value) {
      openPopup(true);
      setTimeout(() => {
        document.body.addEventListener('click', bodyClick);
      }, 100);
    } else {
      bodyClick();
    }
  }

  function bodyClick() {
    closePopup(true);
    document.body.removeEventListener('click', bodyClick);
  }

  onMounted(() => {
    useResizeObserver(window.document.body, () => {
      calculatePostion();
    });
  });

  function calculatePostion() {
    if (!container.value) return;

    const { left, width } =
      container.value.parentElement.getBoundingClientRect();
    const { innerWidth } = window;

    if (isInDialog.value || innerWidth < left + width + minRightWidth) {
      position.value = 'bottom';
    } else {
      position.value = 'right';
      containerWidth.value = Math.min(450, innerWidth - left - width - 40);
    }
  }

  return {
    container,
    open,
    position,
    containerWidth,
    isTouch,
    infoOnClick,
    openPopup,
    closePopup,
    togglePopup,
  };
}
